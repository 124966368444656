<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const colorMode = useColorMode()
</script>

<template>
  <a
    id="netzo-logo"
    href="https://netzo.dev"
    target="_blank"
  >
    <img
      v-if="colorMode.value === 'dark'"
      src="/netzo-logo-dark.svg"
      alt="Netzo"
      v-bind="$attrs"
    >
    <img
      v-else
      src="/netzo-logo-light.svg"
      alt="Netzo"
      v-bind="$attrs"
    >
  </a>
</template>
